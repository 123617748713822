@import '../styles/function';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --light-green: #12B76A;
  --dark-green: #04A88A;
  --black: #000000;
  --white: #FFFFFF;
  --grey: #9497A1;
  --space-grey: #EBEBEB;
  --dark: #212121;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;


}