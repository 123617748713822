@import '../../../styles/function';

.team {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: calc(100vh - 64px);
  background-color: var(--space-grey);
  border-radius: rem(32);
  margin: rem(32);
  box-sizing: border-box;

  @media (max-width: 1200px) {
    height: unset;
  }

  &_header {
    display: flex;
    width: max-content;
    background-color: var(--white);
    padding: rem(36) rem(40);
    border-radius: 0 0 rem(32) 0;
    position: relative;

    @media (max-width: 570px) {
      padding: rem(24) rem(12);
    }

    img {
      @media (max-width: 1366px) {
        height: calc(50.36px * 0.7);
        width: calc(293px * 0.7);
      }
      @media (max-width: 550px) {
        height: 100%;
        width: 136px;
      }
    }

    .radius {
      width: rem(50);
      height: rem(50);
      position: absolute;
      background-color: var(--white);

      @media (max-width: 570px) {
        display: none;
      }

      &.radius_right {
        transform: translateX(100%);
        right: 0;
        top: 0;

        div {
          width: 100%;
          height: 100%;
          border-radius: 2rem 0 0;
          background-color: var(--space-grey);
        }
      }

      &.radius_bottom {
        transform: translateY(100%);
        bottom: 0;
        left: 0;

        div {
          width: 100%;
          height: 100%;
          border-radius: 2rem 0 0 0;
          background-color: var(--space-grey);
        }
      }
    }

  }

  &_body {
    display: flex;
    align-items: center;
    margin-left: rem(112);
    position: relative;
    flex: 1;

    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      margin: rem(112) 0 0 rem(16);
      align-items: unset;
    }

    &_left {
      display: flex;
      flex-direction: column;
      row-gap: rem(36);
      max-width: 50%;

      @media (max-width: 1200px) {
        max-width: unset;
        display: flex;
      }

      h3 {
        margin: 0;
        font-weight: 500;
        font-size: rem(64);
        color: var(--dark);

        @media (max-width: 1200px) {
          font-weight: 500;
          font-size: rem(48);
        }

        @media (max-width: 1366px) {
          font-size: calc(48px * 0.7);
        }

        @media (max-width: 570px) {
          font-size: 24px;
        }
      }

      p {
        margin: 0;
        font-weight: 400;
        font-size: rem(24);
        color: var(--black);

        @media (max-width: 1366px) {
          font-size: calc(24px * 0.7);
        }
        @media (max-width: 570px) {
          font-size: rem(13);
        }
      }

      .buttons {
        display: flex;
        column-gap: rem(16);

        @media (max-width: 1366px) {
          height: calc(60px * 1.1);
          width: calc(208px * 1.1);
        }

        @media (max-width: 570px) {
          display: flex;
          flex-direction: column;
          margin-bottom: rem(50);
          //justify-content: space-between;
        }

        svg {
          @media (max-width: 570px) {
            width: 120px;
            height: 44px;
          }
        }
      }
    }

    &_right {
      position: absolute;
      right: 0;
      max-width: rem(1000);
      width: 50%;

      @media (max-width: 1200px) {
        position: static;
        width: unset;
      }

      img {
        width: 100%;
        @media (max-width: 1366px) {
          width: calc(1000px * 0.5);
          height: calc(925px * 0.5);
        }
        @media (max-width: 750px) {
          width: 100%;
          height: unset;
        }
      }
    }
  }
}