@import '../../../styles/function';

.download_app {
  display: flex;
  align-items: center;
  background: #1C1C1C;
  column-gap: rem(120);
  max-width: 100%;
  height: 735px;
  margin-top: rem(75);

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    max-height: 735px;
  }

  @media (max-width: 1366px) {
    height: calc(735px * 0.7);
    column-gap: calc(120px * 0.7);
  }

  @media (max-width: 750px) {
    column-gap: unset;
    height: unset;
  }

  &_left {
    position: relative;

    @media (max-width: 1200px) {
      display: flex;
    }

    .line {
      position: absolute;
      width: 120%;
      height: 100%;

      @media (max-width: 1200px) {
        width: 108%;
        height: 100%;
        bottom: 0;
      }
      @media (max-width: 750px) {
        width: 100%;
        display: none;
      }
    }

    .app {
      position: relative;
      margin: rem(95) 0 0 rem(188);

      @media (max-width: 1200px) {
        width: rem(618);
        max-width: 100%;
        margin: rem(82) rem(82) 0 0;
      }
      @media (max-width: 1366px) {
        width: calc(863px * 0.7);
        margin-top: calc(95px * 0.7);
        margin-left: calc(188px * 0.7);
      }
      @media (max-width: 750px) {
        width: rem(310);
        height: unset;
        margin: 0;
        margin-top: rem(20);
      }
    }
  }

  &_right {
    display: flex;
    flex-direction: column;
    row-gap: rem(48);

    @media (max-width: 1200px) {
      margin-left: 2rem;
      margin-top: rem(179);
    }
    @media (max-width: 570px) {
      margin: unset;

    }

    h3 {
      font-weight: 500;
      font-size: 64px;
      color: var(--white);
      margin: 0;

      @media (max-width: 1366px) {
        font-size: calc(64px * 0.7);
      }
      @media (max-width: 570px) {
        font-size: rem(24);
      }
    }

    p {
      font-weight: 400;
      font-size: 24px;
      color: var(--white);
      margin: 0;

      @media (max-width: 1366px) {
        font-size: calc(24px * 0.7);
      }
      @media (max-width: 570px) {
        font-size: rem(13);
      }
    }
  }
}