@import './styles/function';

.app {
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    max-width: 1920px;
    width: 100%;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}