@import "../../../styles/function";

.development {
  display: flex;
  align-items: center;
  column-gap: rem(42);
  max-width: 100%;
  margin-left: rem(142);
  margin-bottom: rem(75);
  margin-top: rem(74);

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: unset;
    margin-left: rem(32);
  }


  &_left {
    h3 {
      font-weight: 500;
      font-size: rem(64);
      color: var(--dark-green);

      @media (max-width: 1200px) {
        font-weight: 500;
        font-size: 48px;

        br {
          display: none;
        }
      }
      @media (max-width: 1366px) {
        font-size: calc(48px * 0.7);
      }
      @media (max-width: 570px) {
        font-size: rem(24);
      }
    }

    p {
      font-weight: 400;
      font-size: rem(24);
      color: var(--dark);

      @media (max-width: 1200px) {
        font-weight: 400;
        font-size: 24px;
      }
      @media (max-width: 570px) {
        font-size: rem(13);
      }
    }
  }

  &_right {
    img {
      height: rem(575);

      @media(max-width: 1200px) {
        max-width: 100%;
        height: 428px;
      }
      @media(max-width: 1366px) {
        height: calc(420px * 0.7);
      }
      @media (max-width: 570px) {
        width: 100%;
        height: unset;
      }
    }
  }
}