@import '../../../styles/function';

.city_evolution {
  display: flex;
  margin-top: rem(74);

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column-reverse;
    margin-left: rem(32);
  }

  &_left {
    img {
      height: 575px;
      transform: matrix(-1, 0, 0, 1, 0, 0);

      @media (max-width: 1200px) {
        max-width: 100%;
        height: 428px;
      }
      @media (max-width: 1366px) {
        height: calc(428px * 0.7);
      }
      @media (max-width: 570px) {
        width: 100%;
        height: unset;
      }
    }
  }

  &_right {
    h3 {
      font-weight: 500;
      font-size: rem(64);
      color: var(--dark-green);

      @media (max-width: 1200px) {
        font-weight: 500;
        font-size: rem(48);

        br {
          display: none;
        }
      }
      @media (max-width: 1366px) {
        font-size: calc(48px * 0.7);
      }
      @media (max-width: 570px) {
        font-size: 24px;
      }
    }

    p {
      font-weight: 400;
      font-size: rem(24);
      color: var(--black);

      @media (max-width: 1200px) {
        font-weight: 400;
        font-size: rem(24);
      }
      @media (max-width: 1366px) {
        font-size: calc(24px * 0.7);
      }
      @media (max-width: 570px) {
        font-size: 13px;
      }
    }
  }
}